var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"2","outlined":""}},[_c('base-expansion-panel',{attrs:{"inputs":_vm.inputs,"reactiveVar":_vm.reactiveVar}},[_c('base-form',{attrs:{"searchBtn":true,"resetBtn":true,"inputs":_vm.inputs,"reactiveVar":_vm.reactiveVar,"numberOfCols":3},on:{"submit-form":_vm.apiGetSeiyakuAnken}})],1),_c('base-table',{attrs:{"headers":_vm.seiyakuAnkenKakunin.headers,"items":_vm.filteredAnkens ?? _vm.seiyakuAnkens,"columnName":[
      'item.konponaiyoshusei',
      'item.docDl',
      'item.shipping_bikou_flag',
      'item.unpaid_shipping',
      'item.comment_count',
      'item.picking_complete_flag',
      'item.bikou',
      'item.edit',
      'item.total_weight',
      'item.total_price',
      'item.status_name',
      'item.total_quantity',
    ],"minWidth":"2200","itemKey":_vm.itemKey,"itemClass":_vm.itemClass,"multiSort":"","showSelect":""},on:{"row-selected":(selected) => {
        _vm.rowSelected = selected;
      }},scopedSlots:_vm._u([{key:`item.total_weight`,fn:function({ item }){return [_c('td',{staticStyle:{"text-align":"right"}},[(item.total_weight != null)?_c('span',[_vm._v(_vm._s(item.total_weight.commaString('kg'))+" ")]):_vm._e()])]}},{key:`item.total_quantity`,fn:function({ item }){return [_c('td',{staticStyle:{"text-align":"right"}},[(item.total_quantity != null)?_c('span',[_vm._v(_vm._s(item.total_quantity.commaString()))]):_vm._e()])]}},{key:`item.comment_count`,fn:function({ item }){return [_c('v-lazy',{attrs:{"transition":"fade-transition"}},[_c('v-btn',{attrs:{"color":"primary","x-small":"","align":"center","justify":"center"},on:{"click":function($event){_vm.getDataSeiyakuAnkenCommentActions([item.packing_no]);
            _vm.rowComment = item;
            _vm.showModal = true;}}},[_vm._v("開く")])],1),_c('div',{staticClass:"comment-text",attrs:{"align":"center","justify":"center"}},[_vm._v(" "+_vm._s(item.comment_count ? item.comment_count + '件' : '')+" "+_vm._s(item.last_comment_date)+" ")])]}},{key:`item.picking_complete_flag`,fn:function({ item }){return [(item.cancel_flag === 0)?_c('div',[(!item.picking_complete_flag)?_c('v-lazy',{attrs:{"transition":"fade-transition"}},[_c('v-btn',{attrs:{"disabled":_vm.user.authority != 2 && _vm.user.authority != 9,"color":"warning","x-small":"","align":"center","justify":"center"},on:{"click":function($event){return _vm.showPickingCompConfModal(item)}}},[_vm._v("作業中")])],1):_vm._e(),(item.picking_complete_flag)?_c('v-lazy',{attrs:{"transition":"fade-transition"}},[_c('v-btn',{attrs:{"disabled":_vm.user.authority != 2 && _vm.user.authority != 9,"color":"primary","x-small":"","align":"center","justify":"center"},on:{"click":function($event){return _vm.showPickingWorkingConfModal(item)}}},[_vm._v("完了")])],1):_vm._e()],1):_vm._e()]}},{key:`item.total_price`,fn:function({ item }){return [_c('td',{staticStyle:{"text-align":"right"}},[(item.total_price != null)?_c('span',[_vm._v(_vm._s(item.total_price.commaString('$')))]):_vm._e()])]}},{key:`item.status_name`,fn:function({ item }){return [_c('span',{style:(item.status === 0 ? 'color: red;' : '')},[_vm._v(_vm._s(item.status_name))])]}},{key:`item.unpaid_shipping`,fn:function({ item }){return [_c('v-lazy',{attrs:{"transition":"fade-transition"}},[(item.payment_date)?_c('span',[_vm._v("入金済")]):(item.unpaid_shipping)?_c('span',[_vm._v("未入金出荷実施")]):(
            (_vm.role || _vm.user.authority === 3) && _vm.user.authority != 2 && item.status != 0 && item.cancel_flag != 1
          )?_c('v-btn',{attrs:{"color":"red accent-4 white--text","x-small":""},on:{"click":function($event){_vm.updateUnpaidShippingTarget = item.packing_no;
            _vm.showConfirmModal();}}},[_vm._v("未入金出荷実施ボタン")]):_vm._e()],1)]}},{key:`item.konponaiyoshusei`,fn:function({ item }){return [_c('v-lazy',{attrs:{"transition":"fade-transition"}},[(item.status !== 9 && item.status !== 6 && item.cancel_flag === 0)?_c('v-btn',{attrs:{"color":"primary","x-small":""},on:{"click":function($event){return _vm.redirectToKonpomeisai(item)}}},[_vm._v("選択")]):(item.status !== 9 && item.status !== 6 && item.cancel_flag === 1 && item.editing)?_c('v-btn',{attrs:{"color":"error","x-small":""},on:{"click":function($event){return _vm.undoAnkenCancel(item.invoice_id, item.invoice_no)}}},[_vm._v("案件キャンセル取消")]):(item.status !== 9 && item.status !== 6 && item.cancel_flag === 1)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("案件キャンセル")]):_vm._e()],1)]}},{key:`item.docDl`,fn:function({ item }){return [_c('v-lazy',{attrs:{"transition":"fade-transition"}},[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-btn',{attrs:{"color":"primary","x-small":"","disabled":item.status === 0},on:{"click":function($event){return _vm.downloadPackingExcels({
                packing_nos: [item.packing_no],
                download_all_flag: true,
              })}}},[_vm._v("dl")]),(item.no_battery_img_models && item.no_battery_img_models.length > 0)?_c('tool-tip',{attrs:{"message":item.no_battery_img_models?.join(',<br>'),"classes":"mx-2"}}):_vm._e()],1)])]}},{key:`item.shipping_bikou_flag`,fn:function({ item }){return [_c('v-lazy',{attrs:{"transition":"fade-transition"}},[(item.status != 0 && item.cancel_flag != 1)?_c('div',{staticClass:"d-flex justify-center align-center"},[(item.shipping_description && item.editing)?_c('v-checkbox',{key:item.shipping_bikou_flag,staticClass:"mt-0 pt-0",attrs:{"color":"primary","input-value":item.shipping_bikou_flag,"dense":"","hide-details":""},on:{"change":function($event){return _vm.editSeiyakuAnken([
                {
                  packing_no: item.packing_no,
                  new_shipping_bikou_flag: $event,
                },
              ])}}}):(item.shipping_description)?_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"color":"primary","input-value":item.shipping_bikou_flag,"dense":"","hide-details":"","disabled":""}}):_vm._e()],1):_vm._e()])]}},{key:`item.bikou`,fn:function({ item }){return [(item.editing && _vm.role)?_c('v-textarea',{staticClass:"pa-2 ma-0",attrs:{"background-color":_vm.$vuetify.theme.dark ? 'black' : 'white',"rows":"2","hide-details":"true","value":item.bikou},on:{"change":function($event){return _vm.editSeiyakuAnken([{ packing_no: item.packing_no, new_bikou: $event }])}}}):(item.bikou)?_vm._l((item.bikou.split('\n')),function(ele){return _c('span',{key:ele,staticStyle:{"line-break":"anywhere"}},[_vm._v(_vm._s(ele)),_c('br')])}):_vm._e()]}},{key:`item.edit`,fn:function({ item }){return [(_vm.role || _vm.user.authority === 3)?_c('div',[_c('v-lazy',{attrs:{"transition":"fade-transition"}},[(item.editing)?_c('v-btn',{attrs:{"text":"","icon":"","color":"green lighten-2"},on:{"click":function($event){return _vm.updatePackingInfo([
                {
                  packingNo: item.packing_no,
                  shippingBikouFlag: item.new_shipping_bikou_flag,
                  bikou: item.new_bikou,
                },
              ])}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-content-save")])],1):_c('v-btn',{attrs:{"text":"","icon":"","color":"blue lighten-2"},on:{"click":function($event){return _vm.editSeiyakuAnken([
                {
                  packing_no: item.packing_no,
                  editing: true,
                  new_shipping_bikou_flag: item.shipping_bikou_flag,
                  new_bikou: item.bikou,
                },
              ])}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-pencil-box")])],1)],1),(item.editing)?_c('v-btn',{attrs:{"text":"","icon":"","color":"red lighten-2"},on:{"click":function($event){return _vm.editSeiyakuAnken([
              {
                packing_no: item.packing_no,
                editing: false,
              },
            ])}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-close-circle")])],1):_vm._e()],1):_vm._e()]}}],null,true)}),_c('commentModal',{attrs:{"show-modal":_vm.showModal,"items":_vm.commentItems,"role":_vm.role},on:{"close":_vm.closeModal,"commentRegist":_vm.commentRegist}}),_c('v-card-actions',{staticClass:"justify-center mb-3"},[_c('input',{ref:"nyukinCsvImportRef",staticStyle:{"display":"none"},attrs:{"id":"nyukinFileInput","type":"file","accept":"text/csv"},on:{"change":_vm.importNyukinCsv}}),(_vm.role)?_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.nyukinCsvImportRef.click()}}},[_vm._v(_vm._s(_vm.labels.BTN_NYUKIN_CSV)),_c('v-icon',[_vm._v("mdi-upload")])],1):_vm._e(),_c('v-btn',{attrs:{"color":"blue white--text","disabled":_vm.rowSelected.length === 0},on:{"click":function($event){_vm.downloadPackingExcels({
          packing_nos: _vm.rowSelected.map((row) => row.packing_no),
          download_all_flag: true,
        })}}},[_vm._v(_vm._s(_vm.labels.MULTI_DOWNLOAD_BUTTON)),_c('v-icon',[_vm._v("mdi-file-download")])],1),_c('csv-result-dl',{attrs:{"disabled":_vm.seiyakuAnkens.length === 0,"row-selected":_vm.rowSelected}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }